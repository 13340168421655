<template>
  <div
    class="modal fade"
    id="modal_edit_event"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered mw-700px">
      <div class="modal-content">
        <div class="modal-header">
            <h2>
                <span v-if="event.id" >Edit</span>
                <span v-else>Create</span> Event
            </h2>

          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
        </div>

        <div class="modal-body py-lg-10 px-lg-10">
          <el-form
            id="edit_event_form"
            :model="eventData"
            :rules="rules"
            ref="formRef"
            class="form"
          >
            <div class="row mb-6">
              <label
                class="d-flex align-items-center fs-5 fw-bold mb-2 col-lg-3"
              >
                <span class="required">Type</span>
              </label>
              <div class="col-lg-9">
                <el-form-item prop="type">
                    <el-select
                        class="form-select-solid"
                        v-model="eventData.type">
                        <el-option v-for="type in eventTypes" :key="type" :label="type" :value="type">
                            {{type}}
                        </el-option>
                    </el-select>
                </el-form-item>
              </div>
            </div>
            <div class="row mb-6">
              <label
                class="d-flex align-items-center fs-5 fw-bold mb-2 col-lg-3"
              >
                <span class="required">Title</span>
              </label>
              <div class="col-lg-9">
                <el-form-item prop="title">
                    <input
                        v-model="eventData.title"
                        style="width:100%;"
                        class="form-control form-control-lg form-control-solid"
                    />
                </el-form-item>
              </div>
            </div>
            <div class="row mb-6">
              <label
                class="d-flex align-items-center fs-5 fw-bold mb-2 col-lg-3"
              >
                <span class="required">Date</span>
              </label>
              <div class="col-lg-9">
                <el-form-item prop="event_date"> 
                    <el-date-picker
                        v-model="eventData.period"
                        type="daterange"
                        range-separator="To"
                        start-placeholder="Start date"
                        end-placeholder="End date"
                        class="w-100"
                        format="MM/DD/YYYY"
                    >
                    </el-date-picker>
                </el-form-item>
              </div>
            </div>
            <div class="row mb-6">
              <label
                class="d-flex align-items-center fs-5 fw-bold mb-2 col-lg-3"
              >
                <span>Description</span>
              </label>
              <div class="col-lg-9">
                <el-form-item prop="event_description"> 
                    <textarea
                        v-model="eventData.description"
                        style="width:100%;"
                        class="form-control form-control-lg form-control-solid"
                    />
                </el-form-item>
              </div>
            </div>
            <div class="text-center">
              <button
                id="close_event_button"
                class="btn btn-white me-3"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <el-button type="primary" @click="submit()" :loading="loading">
                {{ loading ? "Please wait..." : "Submit" }}
              </el-button>
              <el-button v-if="event.id" class="btn btn-danger me-3" type="reset" @click="deleteEvent()">
                Delete
              </el-button>
            </div>
            </el-form>
          </div>
        </div>
    </div>
  </div>
</template>


<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import moment from "moment";
import { useFieldValue } from "vee-validate";
import { ElMessageBox } from "element-plus";

export default defineComponent({
  name: "edit-event-modal",
  components: {
  },
  props: {
      event: {} as any,
      eventTypes:[],
      getEvents : {
        type:Function,
        default: () => ({}),
      },
  },
  emits: ['getEvents'],
  setup(props, {emit}) {
    const store = useStore();
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const user = store.getters.currentUser;
    const eventData = ref<any>({});

    const rules = ref({  
        type: [
            {
                required: true,
                message: "Please select event type",
                trigger: "blur",
            },
        ],
        title: [
            {
                required: true,
                message: "Please enter the event title",
                trigger: "change",
            },
        ],
    });

    watch(() => props.event, ()=>{
        eventData.value = props.event;
        if(props.event.start_date){
            eventData.value.period = [];
            eventData.value.period[0] = props.event.start_date;
            eventData.value.period[1] = props.event.end_date;
        }
    })

    const editEvent = async (formData) => {
        ApiService.setHeader();
        if(eventData.value.id && eventData.value.id !==0)
        {
            ApiService.update("event", eventData.value.id, formData)
                .catch(({ response }) => {
                    loading.value = false;
                    console.log('edit event error => '+response.data.errors);
                    Swal.fire({
                    text: "Failed to edit event",
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Ok, got it!",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    });
                });
        }
        else
        {
            ApiService.post("event", formData)
                .catch(({ response }) => {
                    loading.value = false;
                    console.log('create event error => '+response.data.errors);
                    Swal.fire({
                    text: "Failed to create the new event",
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Ok, got it!",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    });
                });
        }
    }

    const submit = () => {
        if (!formRef.value) {
            return;
        }

        formRef.value.validate((valid) => {
            if (valid){
            loading.value = true;
            eventData.value.start_date = eventData.value.period[0];
            eventData.value.end_date = eventData.value.period[1];
            eventData.value.user_id = user.id;
            delete eventData.value.start;
            delete eventData.value.end;
            delete eventData.value.period;
            editEvent(eventData.value).then(() => {
                Swal.fire({
                text: "Form has been successfully submitted!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                }).then(() => {
                    loading.value = false;
                    let closeEditBtn = document.getElementById('close_event_button');
                    closeEditBtn?.click();
                    emit('getEvents');
                });
            });
        } else {
            Swal.fire({
                text: "Please fill out all required fields",
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                confirmButton: "btn btn-primary",
                },
            });
            return false;
            }
        });
    };

    const deleteEvent = () => {
      ElMessageBox.confirm('Are you sure to delete this class?').then(() => {
        ApiService.setHeader();
        ApiService.delete("event/"+eventData.value.id)
          .then(() => {
            Swal.fire({
                text: "The event is deleted successfully",
                icon: "success",
                buttonsStyling: false,  
                confirmButtonText: "Ok, got it!",
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                })
                .then(() => {
                    let closeEditBtn = document.getElementById('close_event_button');
                    closeEditBtn?.click();
                    emit('getEvents');
                });
          })
          .catch(({ response }) => {
            console.log('delete event error => '+response.data.errors);
            Swal.fire({
                text: "Failed to delete event",
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                });
            });
          });
    };

    return {
      loading,
      formRef,
      moment,
      submit,
      eventData,
      rules,
      deleteEvent
    };
  },
});
</script>

<style lang="scss" scoped>
.el-input--suffix .el-input__inner {
  background-color: #f5f8fa;
}

.el-input__inner {
  background-color: #f5f8fa;
}
</style>

<style scoped>
* >>> .el-form-item {
  margin-bottom: 0px;
}

* >>> .el-table td {
    padding: 5px;
    font-size: 13px;
}

* >>> .el-table th {
    padding: 5px;
    font-size: 13px;
    line-height: 20px;
}
</style>

